@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@100;400;700&display=swap);
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background-color: #F2F2F2;
  font-family: 'Josefin Sans', sans-serif;
}
a {
  text-decoration: none !important;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* [type=button]{
  -webkit-appearance: none !important;
} */

